@charset "UTF-8";

$color_main: #002067;
$color_sub: #0096c7;
$color_grad: linear-gradient(90deg, #006AC7, #00BDD2);
$color_accGrad: linear-gradient(90deg, #E63700, #E66B00);


// =================================================
//                  mv
// =================================================
.mv{
  position: relative;
  padding: 60px 0 0;

  @media (max-width: 768px) {
    padding: 32px 0 0;
  }

  &:before{
    content: "";
    position: absolute;
    background: url(/img/top/mv_bg.jpg) no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
  }
  

  a{
    &:before{
      content: none;
    }
  }

  >.inner{
    margin-bottom: 0;
  }

  >.inner >.flex{
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 38px;

    @media (max-width: 993px) {
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 47px;
      gap: 0;
    }
  }

  .mv_image{
    max-width: 482px;
    width: 100%;

    @media (max-width: 768px) {
      max-width: 287px;
    }
  }

  .mv_copy{
    text-align: left;
    display: block;

    @media (max-width: 993px) {
      text-align: center;
      margin-bottom: 40px;
    }


    .small_copy{
      position: relative;
      justify-content: center;
      align-items: center;
      background: $color_main;
      padding: 10px 0 ;
      max-width: 349px;
      width: 100%;
      color: #fff;
      font-size: 1.6rem;
      font-weight: bold;
      border-radius: 40px;
      margin-bottom: 26px;

      @media (max-width: 993px) {
        margin-bottom: 40px;
        margin-right: auto;
        margin-left: auto;
      }

      @media (max-width: 768px) {
        margin-bottom: 20px;
        width: 260px;
        font-size: 1.3rem;
      }

      &:after{
        content: "";
        position: absolute;
        border: 10px solid transparent;
        border-top: 15px solid $color_main;
        left: 45%;
        bottom: -25px;
      }
    }


    img{
      max-width: 674px;
      width: 100%;
      margin-bottom: 30px;

      @media (max-width: 993px) {
        margin-bottom: 42px;
      }

      @media (max-width: 768px) {
        max-width: 320px;
        margin-bottom: 0;
      }

      @media (max-width: 560px) {
        max-width: 250px;
      }
    }

    >p{
      font-size: 1.8rem;
      font-weight: bold;
      color: $color_main;
    }

    // 韓国語
    .ko{
      img{
        width: 70%;

        @media (max-width: 768px) {
          max-width: 300px;
          padding-top: 20px;
        }

        @media (max-width: 560px) {
          max-width: 250px;
        }
      }
    }
  }

}


// =================================================
//                  fix banner
// =================================================
.fix_banner{
  position: fixed;
  right: 20px;
  z-index: 11;
  bottom: 13%;
  transition: all .2s ease-in-out;

  @media (max-width: 768px) {
    bottom: 18%;
  }

  //これだけ556px
  @media (max-width: 556px) {
    bottom: 0;
    right: 0;
  }

  .banner_display{
    position: absolute;
    background: $color_sub;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    top: -10px;
    right: -10px;
    z-index: 5;
    cursor: pointer;

    @media (max-width: 556px) {
      background: none;
      top: 5px;
      right: 5px;
    }

    >span{
      position: absolute;
      background: #fff;
      width: 12px;
      height: 2px;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;

      @media (max-width: 556px) {
        width: 16px;
        background: $color_sub;
      }
    }

    >span:first-child{
      transform: rotate(45deg);
    }

    >span:last-child{
      transform: rotate(-45deg);
    }
  }

  .banner{
    max-width: 428px;
    width: 100%;
    background: #fff;

    @media (max-width: 556px) {
      max-width: 100%;
    }
    
    a{
      width: 100%;
    }

    img{
      width: 100%;
      vertical-align: middle;
    }
  }
}

.fix_banner.close{
  display: none;
  transition: all .2s ease-in-out;
}

@media (max-width: 556px) {
  .ScrollTop.isActive{
    bottom: clamp(16%, 35vw, 22%) !important;
  }

  .ScrollTop.isActive.down{
    bottom: 10px !important;
  }
}

// =================================================
//                  search
// =================================================
.search{
  position: relative;
  margin-top: -80px;

  @media (max-width: 768px) {
    box-shadow: none;
    padding: 0;
  }

  &.search_bar{
    @media (max-width: 768px) {
      margin-top: 0;
    }
  }

  .inner{
    border-radius: 8px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
    padding: 28px;
    background: #fff;
    margin-bottom: 0;

    @media (max-width: 768px) {
      box-shadow: none;
      padding: 0;
    }

    >.flex{
      justify-content: space-between;
      align-items: center;
      
      @media (max-width: 1100px) {
        flex-direction: column;
      }
  
    }
  }

    //========検索form========
    .search_box{
      width: 75%;
      padding-right: 28px;

      @media (max-width: 1100px) {
        width: 100%;
        padding-right: 0;
        padding-bottom: 28px;
      }

      @media (max-width: 768px) {
        padding-bottom: 0;
      }

      &.loing_ing{
        width: 100%;
      }
    }

    .top_sort_box{
      margin-bottom: 16px;
       
        >.flex{
            align-items: center;
            flex-wrap: wrap;
            flex-direction: row;

            @media (max-width: 768px) {
              gap: 8px;
            }
        }

        //雇用形態
        .employment{
            position: relative;
            width: 25%;

            @media (max-width: 768px) {
              width: 100%;
            }

            &:before, &:after{
                content: "";
                position: absolute;
                border-radius: 10px;
                background: #333333;
                width: 9px;
                height: 2px;
                top: 50%;
            }

            &:before{
                transform: rotate(45deg);
                right: 17px;
            }

            &:after{
                transform: rotate(-45deg);
                right: 12px;
            }

            select{
                background-color: #fff;
                color: #333;
                border: 2px solid #DDDDDD;
                border-radius: 4px;
                width: 100%;
                padding: 12px 16px;
                font-size: 1.6rem;
                font-weight: bold;
                text-align: left;
                font-family: "Noto Sans JP", "Hiragino Kaku Gothic ProN", "Meiryo", sans-serif;
                -webkit-appearance: none;
                appearance: none;
                line-height: 1.5;
                cursor: pointer;
            }
        }

        //キーワード
        .keywords{
          width: 50%;

          @media (max-width: 768px) {
            width: 100%;
          }

          input[type=text]{
            resize: none;
            width: 100%;
            padding: 12px 16px;
            border: 2px solid #DDDDDD;
            border-radius: 4px;
            font-family: "Noto Sans JP", "Hiragino Kaku Gothic ProN", "Meiryo", sans-serif;
            font-size: 1.6rem;
            vertical-align: bottom;
            line-height: 1.5;

            &::placeholder{
              font-size: 1.6rem;
              color: #BBBBBB;
              line-height: 1.5;
            }
          }
        }

        //経験・未経験
        .experience{
          display: flex;
          justify-content: center;
          flex-direction: column;

          @media (max-width: 768px) {
            flex-direction: row;
            gap: 10px;
          }

          label{
            position: relative;
            display: block;
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transition: all .2s ease-in-out;

            &:before{
              content: "";
              position: absolute;
              width: 20px;
              height: 20px;
              border: 2px solid #DDDDDD;
              border-radius: 4px;
              left: 0;
              background: #fff;
            }

            input[type=checkbox]{
              visibility: hidden;
            }

            span{
              position: relative;
              text-align: left;
              padding-left: 20px;
              font-family: "Noto Sans JP", "Hiragino Kaku Gothic ProN", "Meiryo", sans-serif;
              font-size: 1.4rem;
              font-weight: bold;

              &:before, &:after{
                content: "";
                position: absolute;
                background: #333333;
                border-radius: 5px;
                width: 10px;
                height: 2px;
                top: 13px;
                transition: all .2s ease-in-out;
                opacity: 0;
              }

              &:before{
                transform: rotate(45deg);
                left: -9px;
              }

              &:after{
                transform: rotate(-45deg);
                left: -3px;
              }
            }

            //check
            input[type=checkbox]:checked + span:before, input[type=checkbox]:checked + span:after{
              opacity: 1;
              transition: all .2s ease-in-out;
            }
          }

          label:first-child{
            margin-bottom: 2px;
          }
        }

        //検索ボタン
        .search_submit{
          background: $color_sub;
          border-radius: 4px;
          padding: 7px 10px;
          width: 48px;
          height: 48px;

          input[type=image]{
            width: 28px;
            height: 34px;
          }
        }
    }

    //注目のキーワード
    .point{
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      margin-bottom: 16px;
      @media (max-width: 768px) {
        align-items: flex-start;
      }

      >p{
        font-size: 1.4rem;
        font-weight: bold;
        text-align: left;
      }

      .keyword{
        ul{
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 8px;

          @media (max-width: 768px) {
            align-items: flex-start;
          }

          li{
            background: #E8EBF1;
            border-radius: 30px;
            // position: relative;

            // &:before, &:after{
            //   content: "";
            //   position: absolute;
            //   background: #333333;
            //   border-radius: 5px;
            //   width: 7px;
            //   height: 1.5px;
            //   right: 8px;
            // }

            // &:before{
            //   transform: rotate(45deg);
            //   top: 12px;
            // }

            // &:after{
            //   transform: rotate(-45deg);
            //   top: 16px;
            // }
            

            a{
              color: #333333;
              font-size: 1.4rem;
              font-weight: bold;
              padding: 4px 12px 4px 12px;
            }
          }
        }
      }
    }

    .point:last-child{
      margin-bottom: 0;
    }

    .user_join{
      width: 25%;
      padding-left: 28px;
      position: relative;

      @media (max-width: 1100px) {
        width: 100%;
        padding-left: 0;
        padding-top: 28px;
      }

      &:before{
        content: "";
        position: absolute;
        border-left: 1px solid #DDDDDD;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        height: 154px;
        width: 1px;

        @media (max-width: 1100px) {
          border-left: none;
          border-bottom: 1px solid #DDDDDD;
          width: 100%;
          height: 1px;
          bottom: unset;
          right: 0;
          margin: 0 auto;
        }

        @media (max-width: 768px) {
          content: none;
        }
      }

      .join_box{
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

        @media (max-width: 1100px) {
          flex-direction: row-reverse;
          justify-content: flex-start;
          align-items: center;
          gap: 20px;
        }

        @media (max-width: 768px) {

          &:before{
            content: "";
            position: absolute;
            width: 100vw;
            height: 100%;
            background: #EEEEEE;
            margin: 0 calc(50% - 50vw);
          }
        }
      }

      //ユーザー登録
      .btn_orangeG{
        max-width: 210px;
        width: 100%;
        margin: 0 auto 16px;

        @media (max-width: 1100px) {
          margin: 0;
        }

        a{
          padding: 12px 20px;
          font-size: 1.6rem;
          font-weight: bold;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
  
          span{
            position: relative;
            padding-left: 24px;

            &:before{
              content: "";
              position: absolute;
              background: url(/img/common/icon_register_white.svg) no-repeat;
              background-size: contain;
              width: 21px;
              height: 22px;
              left: 0;
              top: 2px;
            }
          }
        }
      }
    }

    //詳細条件検索
    .detail_search{
      text-align: right;
      margin: 20px 0 0 auto;
      position: relative;

      @media (max-width: 768px) {
        margin: 20px 0 0 0;
        text-align: left;
        width: 100%;

        &:before{
          content: "";
          position: absolute;
          width: 100vw;
          height: 100%;
          background: #EEEEEE;
          margin: 0 calc(50% - 50vw);
        }
      }
    }
    .detail{
      font-size: 1.4rem;
      font-weight: bold;
      color: #333333;
      position: relative;
      @media (max-width: 768px) {
        padding: 12px 0;
        display: block;
        width: 100%;
        text-align: left;
        text-align: right;

        &:before{
           top: 18px;
         }

         &:after{
           top: 23px;
         }
       }
      span{
        position: relative;
        display: inline-block;
        padding-left: 16px;
        @media (max-width: 768px) {
        }
        &:before, &:after{
          content: "";
          position: absolute;
          background: #333333;
          height: 2px;
          width: 8px;
          left: -4px;
        }
        &:before{
          transform: rotate(45deg);
          top: 6px;
        }
        &:after{
          transform: rotate(-45deg);
          top: 11px;
        }  
      }
    }
}


// =================================================
//                  お休み案内(holiday)
// =================================================
div.holiday{
  margin: 80px 0;
  @media (max-width: 768px) {
    margin: 40px 0;
  }
  .inner{
    margin-bottom: 0;
    border: 2px solid $color_sub;
    padding: 30px;

    @media (max-width: 768px) {
      padding: 20px 12px;
    }
  }

  h2{
    font-size: 2.6rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      font-size: 1.8rem;
      margin-bottom: 15px;
    }
  }

  .holiday_text{
    p{
      font-size: 1.5rem;
      line-height: 2;

      @media (max-width: 768px) {
        text-align: left;
      }
    }

    span{
      font-weight: bold;
      color: #DB504A;
    }
  }
}


// =================================================
//                  special
// =================================================
.special{
  padding: 0 0 100px;

  @media (max-width: 768px) {
    padding: 0 0 60px;
    //休み案内消したら表示
    // margin-top: -66px;

    //休み案内の時表示
    //padding-bottom: 60px;
  }

  // &.login_ing{
  //   @media (max-width: 768px) {
  //     margin-top: 30px;
  //   }
  // }

  .inner{
    margin-bottom: 0;

    @media (max-width: 768px) {
      width: 100%;
      margin: 0;
    }
  }

  h2{
    font-size: 2.8rem;
    font-weight: bold;
    color: #fff;
    text-align: left;
    background: $color_sub;
    padding: 24px 153px;
    border-radius: 8px 8px 0 0;
    position: relative;

    @media (max-width: 768px) {
      font-size: 2rem;
      text-align: center;
      border-radius: 0;
      padding: 12px 0 28px;
    }

    &:before{
      content: "";
      position: absolute;
      background: url(/img/top/special_deco01.png) no-repeat;
      background-size: contain;
      max-width: 101px;
      width: 100%;
      height: 102px;
      left: 36px;
      bottom: 0;

      @media (max-width: 768px) {
        width: 76px;
        height: 76px;
        bottom: unset;
        top: -5px;
      }
    }

    span{
      position: relative;
      padding-right: 80px;

      @media (max-width: 768px) {
        padding-right: 0;
        padding-bottom: 22px;
      }
      
      &:after{
        content: "special";
        position: absolute;
        font-size: 1.6rem;
        font-weight: bold;
        font-family: 'Rubik', sans-serif;
        text-transform: uppercase;
        right: 0;
        bottom: -4px;
        

        @media (max-width: 768px) {
          font-size: 1.2rem;
          left: 0;
          bottom: 0;
          margin: 0 auto;
        }
      }
    }
  }

  .special_bnr{
    background: #F8F8F8;
    border-radius: 0 0 8px 8px;
    padding: 50px 60px;
    align-items: center;
    row-gap: 33px;

    @media (max-width: 768px) {
      border-radius: 0;
      padding: 30px 0 60px;
      overflow: hidden;
    }

    a, form{
      width: calc((100% - 66px) / 3);

      @media (max-width: 993px) {
        width: calc((100% - 33px) / 2);
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      img{
        width: 100%;
        vertical-align: bottom;
        filter: drop-shadow(0px 3px 4px rgba(0, 32, 103, 0.07));

        @media (max-width: 768px) {
          width: 320px;
          margin: 0 auto;
          padding: 0 10px;
        }
      }
    }

    form{
      position: relative;
      transition: all .2s ease-in-out;

      &:hover{
        opacity: 0.7;
      }

      input[type=submit]{
        opacity: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 100%;
      }
    }

    .special_catch{
      margin-top: 10px;
      font-size: 1.3rem;
      line-height: 1.8;
      color: #606060;
      text-align: left;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      @media (max-width: 768px) {
          width: 320px;
          margin: 8px auto 0;
          padding: 0 10px;
      }
    }  
  }

  //slick dots
  .slick-dots{
    bottom: 20px !important;
    right: 0;
    left: 0;

    li button:before{
      content: "" !important;
      width: 6px;
      height: 6px;
      background: #333;
      border-radius: 50%;
    }
  }

  .slick-dotted.slick-slider{
    margin-bottom: 0 !important;
  }
}


// =================================================
//                  スライダー矢印
// =================================================
.slide-arrow{
  position: absolute;
  width: 44px;
  height: 44px;
  background: rgba(0, 150, 199, 0.8);
  border-radius: 50%;
  z-index: 10;
  top: calc(50% + -28px);
  cursor: pointer;

  &:before, &:after{
    content: "";
    position: absolute;
    border-radius: 10px;
    background: #fff;
    width: 13px;
    height: 2.5px;
  }

  &:before{
    top: 39%;
    transform: rotate(45deg);
  }

  &:after{
    top: 56%;
    transform: rotate(-45deg);
  }
}

.prev-arrow{
  left: 5%;

  @media (max-width: 560px) {
    left: 4px;
  }

  &:before, &:after{
    left: 34%;
  }

  &:before{
    transform: rotate(-45deg);
  }

  &:after{
    transform: rotate(45deg);
  }
}

.next-arrow{
  right: 5%;

  @media (max-width: 560px) {
    right: 4px;
  }

  &:before, &:after{
    right: 34%;
  }
}


// =================================================
//                  new_job_list
// =================================================
.new_job_list{
  padding-bottom: 80px;

  //slider
  .slick-list{
    height: 375px !important;
  }
  .slick-track{
    display: flex !important;
    justify-content: center;
    align-items: stretch;
  }

  .slick-slide{
    width: unset !important;
    margin-right: 23px !important;
    margin-left: 23px !important;

    @media (max-width: 768px) {
      margin-right: 21px !important;
      margin-left: 21px !important;
    }
  }

  .slick-slide div{
    width: 100%;
  }

  .slick-slide .one_job{
    width: 336px !important;
  }
}


// =================================================
//                  list
// =================================================
.list{
  margin-bottom: 120px;

  .inner{
    margin-bottom: 120px;

    @media (max-width: 768px) {
      margin-bottom: 60px;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 60px;
  }
}

// =================================================
//                  mv_bnr
// =================================================
.mv_bnr{
  padding-bottom: 80px;

  @media (max-width: 768px) {
    padding-bottom: 60px;
  }

  >.inner{
    margin-bottom: 0 !important;
  }

  a {
    vertical-align: bottom;
  }

  a img, a source{
    max-width: 100%;
    vertical-align: bottom;

    @media (max-width: 768px) {
      max-width: 80%;
    }
  }
}


// =================================================
//                  inner_list
// =================================================
.inner_list{
  .job_list{

    //slider
    .slick-list{
      height: 375px !important;
    }
    .slick-track{
      display: flex !important;
      justify-content: center;
      align-items: stretch;
    }

    .slick-slide{
      width: unset !important;
      margin-right: 23px !important;
      margin-left: 23px !important;

      @media (max-width: 768px) {
        margin-right: 21px !important;
        margin-left: 21px !important;
      }
    }

    .slick-slide div{
      width: 100%;
    }

    .slick-slide .one_job{
      width: 336px !important;
    }
  }
}


// =================================================
//                  news
// =================================================
div.news{
  padding-bottom: 100px;

  @media (max-width: 768px) {
    padding-bottom: 60px;
  }

  >.inner{
    background: #F8F8F8;
    border-radius: 8px;
    padding: 54px 60px;
    margin-bottom: 0;

    @media (max-width: 768px) {
      border-radius: 0;
      padding: 40px 20px;
      margin: 0;
      width: 100%;
    }
  }

  .news_title{
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    >h2{
      font-size: 2.8rem;
      font-weight: bold;
      text-align: left;
      position: relative;
      display: inline-block;
      padding-bottom: 12px;

      @media (max-width: 768px) {
        font-size: 2.4rem;
      }

      &:after{
        content: "";
        position: absolute;
        background: $color_sub;
        width: 40px;
        height: 2px;
        left: 0;
        bottom: 0;

        @media (max-width: 768px) {
          right: 0;
          margin: 0 auto;
        }
      }
    }
  }

  .news_more{
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }

    >a{
      position: relative;
      display: inline-block;
      text-align: left;
      font-size: 1.6rem;
      font-weight: bold;
      color: $color_sub;
      padding-left: 28px;

      &:before{
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 20px;
        height: 20px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background: $color_sub; 
      }

      &:after{
        content: "";
        position: absolute;
        top: 9px;
        left: 6px;
        width: 4px;
        height: 4px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &.sp{
      display: none;
      @media (max-width: 768px) {
        display: block;
        padding: 0;
        text-align: right;
        >a{
          padding-left: 28px;
          &:before{
            top: 2px;
          }
          &:after{
            top: 9px;
          }
        }  
      }
    }
  }

  .news_content{
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  .one_news{
    border-bottom: 1px solid #E1E1E1;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    gap: 43px;

    @media (max-width: 768px) {
      gap: 10px;
      align-items: flex-start;
    }
  }

  .one_news:first-child{
    padding-top: 0;
  }

  .tag_info{
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    flex-wrap: nowrap;

    @media (max-width: 768px) {
      flex-direction: row;
    }

    //日付(非表示)
    // .date{
    //   font-size: 1.6rem;
    //   color: #AAAAAA;
    //   font-weight: 500;
    // }

    >p{
      width: 118px;
      font-size: 1.2rem;
      color: $color_sub;
      font-weight: bold;
      background: #E0EEF3;
      border-radius: 32px;
      padding: 4px;
    }
  }

  .news_text{
    >p{
      font-size: 1.6rem;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-wrap: break-word;

      @media (max-width: 768px) {
        font-size: 1.4rem;
      }

      a{
        color: $color_sub;
        border-bottom: 1px solid $color_sub !important;
        display: unset;
      }
    }
  }
}



// =================================================
//                  client
// =================================================
.client{
  padding: 100px 0;

  >.inner{
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    padding: 60px 0;
  }

  //ロゴスライダー
  .company_logo{
    .flex{
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      @media (max-width: 1080px) {
        justify-content: center;
        row-gap: 40px;
      }
      @media (max-width: 768px) {
        flex-direction: row;
      }
    }

    li{
      max-width: 120px;
      width: 100%;
      @media (max-width: 1080px) {
        max-width: 160px;
      }
      @media (max-width: 640px) {
        max-width: 90px;
      }
    }

    img{
      max-width: 120px;
      width: 100%;
    }

    .size110{
      max-width: 110px;
    }

    .size90{
      max-width: 90px;
    }
  }

  .btn_grad{
    margin: 60px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      margin-top: 40px;
     }
  }
}




// =================================================
//                  cta
// =================================================

.cta{
  background: url(/img/top/cta_bg.jpg) no-repeat center;
  background-size:cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  padding: 100px 0 0;

  @media (max-width: 768px) {
    padding: 60px 0 0;
    background-attachment: unset;
    background: url(/img/top/cta_bg_sp.jpg) no-repeat center;
  }

  >.inner.flex{
    margin-bottom: 0;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: stretch;

    @media (max-width: 993px) {
      flex-direction: column;
      align-items: center;
    }
  }

  img{
    max-width: 550px;
    width: 100%;
    position: relative;
    right: 0;
    top: 7px;

    @media (max-width: 768px) {
      max-width: 440px;
    }

    @media (max-width: 560px) {
      max-width: 335px;
    }
  }
}

.user_enter_content{
  margin-bottom: 100px;

  @media (max-width: 993px) {
    margin-bottom: 60px;
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }

  >h2{
    font-size: clamp(2.4rem, 3.2vw, 4rem);
    font-weight: bold;
    color: #fff;
    text-align: left;
    line-height: 1.6;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  >p{
    font-size: 1.6rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.25;
    text-align: left;

    @media (max-width: 768px) {
      font-size: 1.4rem;
      line-height: 2;
    }
  }

  .btn_orangeG{
    max-width: 280px;
    width: 100%;
    margin: 30px auto 0 0;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    transition: all .2s ease-in-out;

    @media (max-width: 768px) {
      margin: 30px auto 0;
      max-width: 240px;
    }


    a{
      width: 100%;
      font-size: 1.8rem;
      font-weight: bold;
      padding: 17px 0;

      @media (max-width: 768px) {
        font-size: 1.6rem;
      }

      &:hover{
        opacity: unset;
      }
    }

    a span{
      padding-left: 28px;
      line-height: 1.6;
      color: #fff;
      position: relative;

      &:before{
        content: "";
        position: absolute;
        background: url(/img/common/icon_register_white.svg) no-repeat;
        background-size: contain;
        width: 25px;
        height: 26px;
        left: 0;
        top: 2px;
      }
    }

    &.search_icon{
      a span:before{
        background: url(/img/common/icon_search_white.svg) no-repeat;
        background-size: contain;
      }
    }
  }
}



// =================================================
//                  concept
// =================================================
.concept{
  //for_user, for_compnay共通
  .for_title{
    text-align: left;

    @media (max-width: 768px) {
      text-align: center;
    }

    >h2{
      font-size: 3.2rem;
      font-weight: bold;
      color: #333333;
      text-align: left;
      margin-bottom: 40px;
      margin-top: 11px;
      line-height: 1.6;
      display: block;

      @media (max-width: 768px) {
        font-size: 2.4rem;
        text-align: center;
      }
    }

    >span{
      font-size: 2.4rem;
      line-height: 1.5;
      text-transform: uppercase;
      font-weight: bold;

      @media (max-width: 768px) {
        font-size: 1.8rem;
      }
    }
  }

  .for_user_content{
    >.text{
      margin-bottom: 60px;

      @media (max-width: 768px) {
        margin-bottom: 40px;
      }
      >p{
        font-size: 1.6rem;
        text-align: left;
        font-weight: 500;
        line-height: 2.2;

        @media (max-width: 768px) {
          font-size: 1.4rem;
        }
      }

      >p:first-child{
        margin-bottom: 30px;
      }
    }
  }

  .point_content{
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 30px;
    margin-bottom: 40px;
    padding-top: 4;

    @media (max-width: 993px) {
      flex-wrap: wrap;
    }

    >.point{
      background: #fff;
      border-radius: 8px;
      padding: 36px 0;
      width: 281px;
      position: relative;

      >span{
        position: absolute;
        font-size: 2.6rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $color_sub;
        top: -8px;
        right: 0;
        left: 0;
        line-height: 1;
      }

      >p{
        font-size: 1.8rem;
        font-weight: bold;
      }
    }
  }
  
  .for_btn{
    flex-wrap: nowrap;
    justify-content: right;
    align-items: center;
    flex-direction: row-reverse;
    gap: 20px;

    @media (max-width: 993px) {
      justify-content: center;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }

    a{
      max-width: 240px;
      width: 100%;
      padding: 17px 0;
      font-size: 1.8rem;
      font-weight: bold;
      justify-content: center;
      align-items: center;

      @media (max-width: 768px) {
        font-size: 1.6rem;
      }

      @media (hover: hover){
        &:hover{
          opacity: 1;
        }
      }

      &.btn_bluebord{
        @media (hover: hover){
          &:hover{
            opacity: 0.7;
          }
        }  
      }
    }

  }

  .for_user, .for_company{
    position: relative;

    //灰色背景
    &:before{
      content: "";
      position: absolute;
      background: #F8F8F8;
      height: 100%;
      width: 90vw;
      top: 0;
      z-index: -1;

      @media (max-width: 993px) {
        width: 100vw;
      }
    }      
  }

  .for_user{
    margin-top: 120px;
    margin-bottom: 80px;
    padding: 100px 100px 100px 0;

    @media (max-width: 993px) {
      padding: 60px 0;
    }

    @media (max-width: 768px) {
      margin-top: 0;
      margin-bottom: 0;
    }

    //灰色背景
    &:before{
      border-radius: 8px 0 8px 0;
      left: 0;
      margin-left: calc(50% - 50vw);
    }
  }

  .for_company{
    padding: 100px 0 100px 100px;

    @media (max-width: 993px) {
      padding: 60px 0;
    }

    @media (max-width: 768px) {
      padding-top: 0;
    }

      //灰色背景
      &:before{
        border-radius: 8px 0 8px 0;
        right: 0;
        margin-right: calc(50% - 50vw);
      }
  }
}


// =================================================
//                  login sp
// =================================================
.login.sp{

  @media (min-width: 769px) {
    display: none;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-bottom: 30px;

  .btn_orangeG, .btn_mainB{
    max-width: 287px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    a{
      padding: 12px 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      font-weight: bold;
      position: relative;
      line-height: 1.5;

      &:before{
        content: "";
        position: absolute;
        left: 45px;
        top: 14px;
      }
    }
  }

  .btn_orangeG{
    margin-bottom: 16px;

    a{
      color: #fff;

      &:before{
        background: url(/img/common/icon_register_white.svg) no-repeat;
        background-size: contain;
        width: 21px;
        height: 22px;
      }
    }
  }

  .btn_mainB{

    a{
      color: #fff;
      &:before{
        background: url(/img/common/icon_login_white.svg) no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;  
      }
    }
  }
}

// =================================================
//                  fadeUp
// =================================================
.fadeUp{
  animation-name:fadeUpAnime;
  animation-duration:0.5s;
  animation-fill-mode:forwards;
  opacity:0;
  }
  
  
  @keyframes fadeUpAnime{
    from {
      opacity: 0;
    transform: translateY(100px);
    }
  
    to {
      opacity: 1;
    transform: translateY(0);
    }
  }
  
  
  /* スクロールをしたら出現する要素にはじめに透過0を指定　*/
  .fadeUpTrigger{
      opacity: 0;
  }